import { FC, HTMLAttributes } from 'react';

type FacebookProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Facebook: FC<FacebookProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 24) / 25}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.2109 2H14.2109C12.8849 2 11.6131 2.52678 10.6754 3.46447C9.73772 4.40215 9.21094 5.67392 9.21094 7V10H6.21094V14H9.21094V22H13.2109V14H16.2109L17.2109 10H13.2109V7C13.2109 6.73478 13.3163 6.48043 13.5038 6.29289C13.6914 6.10536 13.9457 6 14.2109 6H17.2109V2Z"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
