import { FC, HTMLAttributes } from 'react';

type ChevronRightProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const ChevronRight: FC<ChevronRightProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M9.17969 18.1147L15.1797 12.1147L9.17969 6.11475"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronRight;
