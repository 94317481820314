import { FC, HTMLAttributes } from 'react';

type RedditProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Reddit: FC<RedditProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 24) / 25}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M23.4601 12.1512C23.4097 10.791 22.2678 9.72889 20.9034 9.77507C20.2989 9.79606 19.7238 10.0437 19.2871 10.4594C17.3644 9.14955 15.1058 8.43167 12.7843 8.38129L13.88 3.11265L17.4945 3.87251C17.5953 4.80029 18.4265 5.47199 19.3543 5.37124C20.2821 5.27048 20.9538 4.43926 20.853 3.51147C20.7523 2.58369 19.9211 1.91199 18.9933 2.01274C18.4601 2.06732 17.9815 2.37798 17.717 2.83977L13.5777 2.01274C13.2964 1.94977 13.0152 2.12609 12.9522 2.41156C12.9522 2.41576 12.9522 2.41576 12.9522 2.41996L11.7053 8.28054C9.35439 8.31832 7.06641 9.0404 5.11848 10.3586C4.12773 9.42662 2.56603 9.4728 1.63404 10.4678C0.70206 11.4585 0.74824 13.0202 1.74319 13.9522C1.93631 14.1327 2.15881 14.2881 2.4065 14.3972C2.38971 14.6449 2.38971 14.8926 2.4065 15.1403C2.4065 18.9228 6.81453 22 12.2511 22C17.6877 22 22.0957 18.927 22.0957 15.1403C22.1125 14.8926 22.1125 14.6449 22.0957 14.3972C22.9437 13.9732 23.4769 13.1 23.4601 12.1512ZM6.57104 13.843C6.57104 12.9111 7.33089 12.1512 8.26288 12.1512C9.19486 12.1512 9.95472 12.9111 9.95472 13.843C9.95472 14.775 9.19486 15.5349 8.26288 15.5349C7.3267 15.5265 6.57104 14.775 6.57104 13.843ZM16.382 18.4862C15.1814 19.3888 13.712 19.8506 12.2091 19.7876C10.7062 19.8506 9.23684 19.3888 8.03618 18.4862C7.87665 18.2931 7.90604 18.0034 8.09915 17.8439C8.26708 17.7053 8.50637 17.7053 8.67849 17.8439C9.69444 18.5869 10.9329 18.9648 12.1923 18.906C13.4518 18.9732 14.6944 18.6121 15.7229 17.8774C15.9077 17.6969 16.2099 17.7011 16.3904 17.8858C16.571 18.0706 16.5668 18.3728 16.382 18.5533V18.4862ZM16.0798 15.5937C15.1478 15.5937 14.3879 14.8338 14.3879 13.9018C14.3879 12.9698 15.1478 12.21 16.0798 12.21C17.0118 12.21 17.7716 12.9698 17.7716 13.9018C17.8094 14.8338 17.0831 15.6189 16.1511 15.6566C16.1218 15.6566 16.0966 15.6566 16.0672 15.6566L16.0798 15.5937Z"
        fill={color}
      />
    </svg>
  );
};

export default Reddit;
