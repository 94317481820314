import { FC, HTMLAttributes } from 'react';

type ImageFullProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const ImageFull: FC<ImageFullProps> = ({
  size = 55,
  color = '#8E8E92',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={(23 * size) / 55}
      viewBox="0 0 55 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.9023 1.43311V21.4331"
        stroke="#B4B4B7"
        stroke-width="2.04877"
        stroke-linecap="round"
      />
      <path
        d="M19.9023 12.4575C20.4681 12.4575 20.9267 11.9989 20.9267 11.4331C20.9267 10.8674 20.4681 10.4087 19.9023 10.4087L19.9023 12.4575ZM1.178 10.7088C0.777949 11.1088 0.777948 11.7574 1.178 12.1575L7.69713 18.6766C8.09718 19.0766 8.74578 19.0766 9.14583 18.6766C9.54588 18.2765 9.54588 17.6279 9.14583 17.2279L3.35104 11.4331L9.14583 5.63832C9.54588 5.23827 9.54588 4.58967 9.14583 4.18962C8.74578 3.78957 8.09718 3.78957 7.69713 4.18962L1.178 10.7088ZM19.9023 10.4087L1.90234 10.4087L1.90234 12.4575L19.9023 12.4575L19.9023 10.4087Z"
        fill={color}
      />
      <path
        d="M54.6267 12.1575C55.0267 11.7574 55.0267 11.1088 54.6267 10.7088L48.1076 4.18962C47.7075 3.78957 47.0589 3.78957 46.6589 4.18962C46.2588 4.58967 46.2588 5.23827 46.6589 5.63832L52.4536 11.4331L46.6589 17.2279C46.2588 17.6279 46.2588 18.2765 46.6589 18.6766C47.0589 19.0766 47.7075 19.0766 48.1076 18.6766L54.6267 12.1575ZM35.9023 10.4087C35.3366 10.4087 34.878 10.8674 34.878 11.4331C34.878 11.9989 35.3366 12.4575 35.9023 12.4575L35.9023 10.4087ZM53.9023 10.4087L35.9023 10.4087L35.9023 12.4575L53.9023 12.4575L53.9023 10.4087Z"
        fill={color}
      />
    </svg>
  );
};

export default ImageFull;
