import { FC, HTMLAttributes } from 'react';

type EmbedIconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EmbedIcon: FC<EmbedIconProps> = ({
  size = 24,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      {...props}
    >
      <path d="m18 16 4-4-4-4" />
      <path d="m6 8-4 4 4 4" />
      <path d="m14.5 4-5 16" />
    </svg>
  );
};

export default EmbedIcon;
