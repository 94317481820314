import { FC, HTMLAttributes } from 'react';

type FormsPageProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const FormsPage: FC<FormsPageProps> = ({
  size = 20,
  color = '#030712',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={(21 * size) / 20}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.6667 14.4238V17.9521C16.6667 18.3852 16.2936 18.7362 15.8333 18.7362H13.125"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6666 7.75909V3.83876C16.6666 3.40573 16.2935 3.05469 15.8333 3.05469H4.16659C3.70635 3.05469 3.33325 3.40573 3.33325 3.83876V17.952C3.33325 18.385 3.70635 18.736 4.16659 18.736H6.66659"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 7.56201H12.4998"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M9.58325 18.7361L16.6666 10.5034"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6.6665 10.8955H9.99984"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default FormsPage;
