type ChevronFilledDownProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const ChevronFilledDown: React.FC<ChevronFilledDownProps> = ({
  size = 24,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={size}
      height={(size * 3) / 4}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.52292 5.44806C4.32273 5.70429 3.93508 5.70429 3.73489 5.44806L0.385018 1.16021C0.128556 0.831943 0.362456 0.352393 0.77903 0.352393L7.47878 0.352393C7.89536 0.352393 8.12926 0.831944 7.8728 1.16022L4.52292 5.44806Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronFilledDown;
