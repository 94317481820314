import { FC, HTMLAttributes } from 'react';

type CheckCircleIconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const CheckCircle: FC<CheckCircleIconProps> = ({
  size = 40,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6463_60327)">
        <path
          d="M18.4141 10.189V10.879C18.4131 12.4964 17.8894 14.0701 16.9211 15.3654C15.9527 16.6608 14.5915 17.6084 13.0406 18.067C11.4896 18.5255 9.832 18.4705 8.31491 17.91C6.79782 17.3495 5.50255 16.3136 4.62228 14.9568C3.74201 13.6001 3.32391 11.9951 3.43032 10.3813C3.53673 8.76747 4.16197 7.23129 5.21277 6.00185C6.26358 4.77241 7.68365 3.91559 9.2612 3.55916C10.8388 3.20273 12.4893 3.3658 13.9666 4.02405"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2414 4.1499L11.09 12.3095L8.64453 9.86405"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6463_60327">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.914062 0.874756)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircle;
