import { FC, HTMLAttributes } from 'react';

type BookOpenProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const BookOpen: FC<BookOpenProps> = ({
  size = 24,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66675 2.33325H5.33341C6.80618 2.33325 8.00008 3.52715 8.00008 4.99992V13.9999C8.00008 12.8954 7.10465 11.9999 6.00008 11.9999H1.66675V2.33325Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M14.3333 2.33325H10.6667C9.1939 2.33325 8 3.52715 8 4.99992V13.9999C8 12.8954 8.89543 11.9999 10 11.9999H14.3333V2.33325Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BookOpen;
