import { FC, HTMLAttributes } from 'react';

type MailProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Mail: FC<MailProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 24) / 25}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M4.21094 4H20.2109C21.2552 4 22.1125 4.80033 22.203 5.82107L12.7114 11.8525L12.7092 11.8539C12.5601 11.9468 12.388 11.996 12.2124 11.996C12.0367 11.996 11.8646 11.9468 11.7155 11.8539L2.21899 5.81935C2.3103 4.79943 3.16726 4 4.21094 4Z"
        fill={color}
      />
      <path
        d="M2.21094 8.18388V18C2.21094 19.1046 3.10637 20 4.21094 20H20.2109C21.3155 20 22.2109 19.1046 22.2109 18V8.1857L13.7733 13.5474C13.3054 13.8405 12.7645 13.996 12.2124 13.996C11.6603 13.996 11.1193 13.8405 10.6514 13.5474L10.646 13.544L2.21094 8.18388Z"
        fill={color}
      />
    </svg>
  );
};

export default Mail;
