import { FC, HTMLAttributes } from 'react';

type CopyProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Copy: FC<CopyProps> = ({
  size = 21,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_3689_13713)">
        <path
          d="M16.9635 8.40283H9.46354C8.54307 8.40283 7.79688 9.14902 7.79688 10.0695V17.5695C7.79688 18.49 8.54307 19.2362 9.46354 19.2362H16.9635C17.884 19.2362 18.6302 18.49 18.6302 17.5695V10.0695C18.6302 9.14902 17.884 8.40283 16.9635 8.40283Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.46484 13.4029H3.63151C3.18948 13.4029 2.76556 13.2273 2.453 12.9148C2.14044 12.6022 1.96484 12.1783 1.96484 11.7362V4.23625C1.96484 3.79422 2.14044 3.3703 2.453 3.05774C2.76556 2.74517 3.18948 2.56958 3.63151 2.56958H11.1315C11.5735 2.56958 11.9975 2.74517 12.31 3.05774C12.6226 3.3703 12.7982 3.79422 12.7982 4.23625V5.06958"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3689_13713">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.296875 0.902832)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Copy;
