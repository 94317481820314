type ChevronUpProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const ChevronUp: React.FC<ChevronUpProps> = ({
  size = 23,
  color = '#8E8E92',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1485 14.7614C16.7905 15.1194 16.2101 15.1194 15.8521 14.7614L11.0003 9.9096L6.14851 14.7614C5.79053 15.1194 5.21013 15.1194 4.85215 14.7614C4.49416 14.4034 4.49416 13.823 4.85215 13.4651L10.3521 7.96506C10.7101 7.60708 11.2905 7.60708 11.6485 7.96506L17.1485 13.4651C17.5065 13.823 17.5065 14.4034 17.1485 14.7614Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronUp;
