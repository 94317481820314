import { FC, HTMLAttributes } from 'react';

type TextIllustrationProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const TextIllustration: FC<TextIllustrationProps> = ({
  size = 81,
  color = '#B4B4B7',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 81 81"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_3229_31914)">
        <g filter="url(#filter0_d_3229_31914)">
          <path
            d="M53.7031 70.6612C46.3065 71.3328 36.1044 64.9081 30.5437 60.9052C28.1429 59.177 27.0407 55.9877 27.7651 53.3425C28.5727 50.3941 31.3935 49.0132 34.3148 50.1363L37.6523 51.4195L27.512 33.8561C26.2946 31.7474 26.6904 29.2396 28.3962 28.2548C30.1019 27.27 32.4716 28.1811 33.6891 30.2898L38.9796 39.4533C37.7622 37.3446 38.158 34.8368 39.8637 33.852C41.5694 32.8672 43.9392 33.7783 45.1566 35.887L46.9201 38.9415C45.7027 36.8328 46.0985 34.325 47.8043 33.3402C49.51 32.3554 51.8797 33.2665 53.0972 35.3752L54.8607 38.4297C53.6432 36.321 54.0391 33.8132 55.7448 32.8284C57.4505 31.8436 59.8203 32.7547 61.0377 34.8634L67.9754 46.8798C68.9191 48.5144 69.5249 50.3185 69.534 52.0944C69.5491 55.0409 69.2263 59.731 67.2925 62.8154C64.2913 67.6027 60.543 70.0402 53.7031 70.6612Z"
            fill="white"
          />
          <path
            d="M53.7031 70.6612C46.3065 71.3328 36.1044 64.9081 30.5437 60.9052C28.1429 59.177 27.0407 55.9877 27.7651 53.3425C28.5727 50.3941 31.3935 49.0132 34.3148 50.1363L37.6523 51.4195L27.512 33.8561C26.2946 31.7474 26.6904 29.2396 28.3962 28.2548C30.1019 27.27 32.4716 28.1811 33.6891 30.2898L38.9796 39.4533C37.7622 37.3446 38.158 34.8368 39.8637 33.852C41.5694 32.8672 43.9392 33.7783 45.1566 35.887L46.9201 38.9415C45.7027 36.8328 46.0985 34.325 47.8043 33.3402C49.51 32.3554 51.8797 33.2665 53.0972 35.3752L54.8607 38.4297C53.6432 36.321 54.0391 33.8132 55.7448 32.8284C57.4505 31.8436 59.8203 32.7547 61.0377 34.8634L67.9754 46.8798C68.9191 48.5144 69.5249 50.3185 69.534 52.0944C69.5491 55.0409 69.2263 59.731 67.2925 62.8154C64.2913 67.6027 60.543 70.0402 53.7031 70.6612Z"
            stroke={color}
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <path
          d="M10.6992 26.3917L17.326 28.1674"
          stroke={color}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.459 13.73L22.7105 21.3239"
          stroke={color}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.8965 12.8633L30.5213 18.6816"
          stroke={color}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3229_31914"
          x="17.3125"
          y="20.3466"
          width="61.7227"
          height="61.8633"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3229_31914"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3229_31914"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3229_31914">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(0.902344 0.0327148)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TextIllustration;
