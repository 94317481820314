import { FC, HTMLAttributes } from 'react';

type LinkedInProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const LinkedIn: FC<LinkedInProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 24) / 25}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M15.64 8.31567C17.3151 8.31567 18.9215 8.98109 20.1059 10.1655C21.2904 11.35 21.9558 12.9564 21.9558 14.6315V21.9999H17.7453V14.6315C17.7453 14.0731 17.5235 13.5376 17.1287 13.1428C16.7338 12.748 16.1984 12.5262 15.64 12.5262C15.0817 12.5262 14.5462 12.748 14.1514 13.1428C13.7565 13.5376 13.5347 14.0731 13.5347 14.6315V21.9999H9.32422V14.6315C9.32422 12.9564 9.98963 11.35 11.1741 10.1655C12.3585 8.98109 13.965 8.31567 15.64 8.31567Z"
        fill={color}
      />
      <path d="M6.67537 8.99268H2.46484V22H6.67537V8.99268Z" fill={color} />
      <path
        d="M4.57011 6.21053C5.73281 6.21053 6.67537 5.26797 6.67537 4.10526C6.67537 2.94256 5.73281 2 4.57011 2C3.4074 2 2.46484 2.94256 2.46484 4.10526C2.46484 5.26797 3.4074 6.21053 4.57011 6.21053Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedIn;
