import { FC, HTMLAttributes } from 'react';

type FarcasterProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Farcaster: FC<FarcasterProps> = ({
  size = 22,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.85877 18.2363H0.613761V17.5396C0.613761 17.2189 0.868469 16.9589 1.18264 16.9589H1.2964V16.2621C1.2964 15.9415 1.55109 15.6815 1.86526 15.6815V5.346H1.23952L0.5 2.79115H3.79941V0.236328H16.2006V2.79115H19.5L18.7605 5.346H18.1347V15.6815C18.4489 15.6815 18.7036 15.9415 18.7036 16.2621V16.9589H18.8174C19.1315 16.9589 19.3862 17.2189 19.3862 17.5396V18.2363H13.1482V17.5396C13.1482 17.2189 13.403 16.9589 13.7171 16.9589H13.8309V16.2621C13.8309 15.9481 14.0751 15.6923 14.3802 15.6818V9.99117C14.179 7.7124 12.2849 5.92664 10 5.92664C7.7151 5.92664 5.82097 7.7124 5.61976 9.99117V15.6816C5.92815 15.6884 6.17613 15.9457 6.17613 16.2621V16.6105V16.9589H6.28989C6.60406 16.9589 6.85877 17.2189 6.85877 17.5396V18.2363Z"
        fill={color}
      />
    </svg>
  );
};

export default Farcaster;
