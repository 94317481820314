import { FC, HTMLAttributes } from 'react';

type OpenWindowProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const OpenWindow: FC<OpenWindowProps> = ({
  size = 18,
  color = '#8E8E92',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M11.7969 1.95312H16.7969V6.95312"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.62891 11.1198L16.7956 1.95312"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2969 10.2865V15.2865C14.2969 15.7285 14.1213 16.1524 13.8087 16.465C13.4962 16.7775 13.0722 16.9531 12.6302 16.9531H3.46354C3.02151 16.9531 2.59759 16.7775 2.28503 16.465C1.97247 16.1524 1.79688 15.7285 1.79688 15.2865V6.11979C1.79688 5.67776 1.97247 5.25384 2.28503 4.94128C2.59759 4.62872 3.02151 4.45313 3.46354 4.45312H8.46354"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7969 1.95312H16.7969V6.95312"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default OpenWindow;
