import { FC, SVGProps } from 'react';

type WalletProps = SVGProps<SVGSVGElement> & {
  color?: string;
  size?: number;
};

const Telegram: FC<WalletProps> = ({
  size = 21,
  color = 'currentColor',
  ...props
}) => (
  <svg
    width={size}
    height={(size * 23) / 22}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.20344 11.1515C3.14366 10.0912 5.30948 9.20623 7.33311 8.31677C10.8145 6.8599 14.3098 5.42828 17.8403 4.09546C18.5272 3.86837 19.7615 3.64632 19.8825 4.65622C19.8162 6.08578 19.5436 7.50698 19.3566 8.92817C18.882 12.0535 18.3335 15.1681 17.7985 18.2832C17.6142 19.3208 16.3041 19.8579 15.4658 19.1939C13.4511 17.8438 11.421 16.5068 9.4321 15.1254C8.78059 14.4687 9.38475 13.5255 9.96661 13.0564C11.6259 11.4341 13.3857 10.0557 14.9583 8.3495C15.3825 7.33323 14.1291 8.1897 13.7156 8.45216C11.444 10.0052 9.228 11.6531 6.833 13.018C5.60964 13.6861 4.18379 13.1152 2.96098 12.7424C1.86461 12.292 0.25797 11.8383 1.20333 11.1516L1.20344 11.1515Z"
      fill={color}
    />
  </svg>
);
export default Telegram;
