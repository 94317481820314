import { FC, HTMLAttributes } from 'react';

type WorldIdIconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const WorldId: FC<WorldIdIconProps> = ({
  size = 40,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6455_59577"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path
          d="M19.4141 0.374756H0.414062V19.3748H19.4141V0.374756Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6455_59577)">
        <path
          d="M18.7328 6.34642C18.4805 5.71561 18.1609 5.11845 17.7824 4.55492C16.075 2.03168 13.1817 0.374756 9.90986 0.374756C4.66152 0.374756 0.414062 4.63062 0.414062 9.87055C0.414062 15.1189 4.66993 19.3748 9.90986 19.3748C13.1901 19.3748 16.075 17.7178 17.7824 15.1946C18.1609 14.6395 18.4721 14.0423 18.7328 13.4115C19.1701 12.3181 19.4141 11.1238 19.4141 9.87896C19.4057 8.63416 19.1701 7.43983 18.7328 6.34642ZM17.4628 8.91172H6.58759C6.75581 8.32296 7.06701 7.80149 7.48755 7.38936C8.12677 6.75014 9.0099 6.35484 9.98555 6.35484H16.6637C17.0759 7.13704 17.345 7.99494 17.4628 8.91172ZM9.85939 2.21672C12.021 2.21672 13.9807 3.11668 15.3769 4.56333H10.2211C8.74917 4.56333 7.42026 5.1605 6.46143 6.11933C5.71287 6.86789 5.1914 7.82673 4.99795 8.91172H2.26444C2.73544 5.13527 5.95678 2.21672 9.85939 2.21672ZM9.85939 17.5496C5.95678 17.5496 2.73544 14.6311 2.25603 10.8546H4.98954C5.44372 13.3274 7.61371 15.203 10.2211 15.203H15.3769C13.9891 16.6497 12.0294 17.5496 9.85939 17.5496ZM9.98555 13.4199C8.37068 13.4199 7.00813 12.3349 6.58759 10.8546H17.4628C17.345 11.7714 17.0674 12.6293 16.6637 13.4199H9.98555Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default WorldId;
