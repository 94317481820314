type ChevronFilledUpProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const ChevronFilledUp: React.FC<ChevronFilledUpProps> = ({
  size = 24,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={size}
      height={(size * 3) / 4}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.7349 0.85663C3.93508 0.600393 4.32273 0.600394 4.52292 0.856631L7.8728 5.14447C8.12926 5.47274 7.89536 5.9523 7.47878 5.9523L0.779028 5.95229C0.362454 5.95229 0.128555 5.47274 0.385016 5.14447L3.7349 0.85663Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronFilledUp;
