import { FC, HTMLAttributes } from 'react';

type XIconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const X: FC<XIconProps> = ({ size = 40, color = 'currentColor', ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7512 3.19727H20.818L14.1179 10.855L22 21.2754H15.8284L10.9946 14.9555L5.4636 21.2754H2.39492L9.56132 13.0846L2 3.19727H8.32824L12.6976 8.97395L17.7512 3.19727ZM16.6748 19.4398H18.3742L7.40492 4.93647H5.58132L16.6748 19.4398Z"
        fill={color}
      />
    </svg>
  );
};

export default X;
