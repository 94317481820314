import { FC, HTMLAttributes } from 'react';

type LinkProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Link: FC<LinkProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 24) / 25}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10.1279 12.9363C10.53 13.4738 11.043 13.9186 11.632 14.2404C12.2211 14.5622 12.8725 14.7536 13.542 14.8015C14.2115 14.8495 14.8835 14.7529 15.5125 14.5183C16.1414 14.2837 16.7125 13.9166 17.187 13.4418L19.9957 10.6332C20.8484 9.75032 21.3202 8.56786 21.3095 7.3405C21.2989 6.11313 20.8066 4.93905 19.9387 4.07114C19.0707 3.20322 17.8967 2.71092 16.6693 2.70025C15.4419 2.68958 14.2595 3.16141 13.3766 4.01411L11.7663 5.61505"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8722 11.0637C13.4702 10.5262 12.9572 10.0815 12.3681 9.75965C11.7791 9.43781 11.1277 9.24643 10.4582 9.19848C9.78863 9.15053 9.11662 9.24714 8.4877 9.48174C7.85879 9.71634 7.28768 10.0835 6.81313 10.5582L4.00447 13.3668C3.15177 14.2497 2.67994 15.4322 2.69061 16.6595C2.70127 17.8869 3.19358 19.061 4.06149 19.9289C4.92941 20.7968 6.10348 21.2891 7.33085 21.2998C8.55822 21.3104 9.74068 20.8386 10.6235 19.9859L12.2245 18.385"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Link;
