import { FC, HTMLAttributes } from 'react';

type ImageCenterProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const ImageCenter: FC<ImageCenterProps> = ({
  size = 55,
  color = '#8E8E92',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={(23 * size) / 55}
      viewBox="0 0 55 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.9023 1.43311V21.4331"
        stroke="#B4B4B7"
        stroke-width="2.04877"
        stroke-linecap="round"
      />
      <path
        d="M20.6267 12.1575C21.0267 11.7574 21.0267 11.1088 20.6267 10.7088L14.1076 4.18962C13.7075 3.78957 13.0589 3.78957 12.6589 4.18962C12.2588 4.58967 12.2588 5.23827 12.6589 5.63832L18.4536 11.4331L12.6589 17.2279C12.2588 17.6279 12.2588 18.2765 12.6589 18.6766C13.0589 19.0766 13.7075 19.0766 14.1076 18.6766L20.6267 12.1575ZM1.90234 10.4087C1.33659 10.4087 0.87796 10.8674 0.87796 11.4331C0.87796 11.9989 1.33659 12.4575 1.90234 12.4575L1.90234 10.4087ZM19.9023 10.4087L1.90234 10.4087L1.90234 12.4575L19.9023 12.4575L19.9023 10.4087Z"
        fill={color}
      />
      <path
        d="M53.9023 12.4575C54.4681 12.4575 54.9267 11.9989 54.9267 11.4331C54.9267 10.8674 54.4681 10.4087 53.9023 10.4087L53.9023 12.4575ZM35.178 10.7088C34.7779 11.1088 34.7779 11.7574 35.178 12.1575L41.6971 18.6766C42.0972 19.0766 42.7458 19.0766 43.1458 18.6766C43.5459 18.2765 43.5459 17.6279 43.1458 17.2279L37.351 11.4331L43.1458 5.63832C43.5459 5.23827 43.5459 4.58967 43.1458 4.18962C42.7458 3.78957 42.0972 3.78957 41.6971 4.18962L35.178 10.7088ZM53.9023 10.4087L35.9023 10.4087L35.9023 12.4575L53.9023 12.4575L53.9023 10.4087Z"
        fill={color}
      />
    </svg>
  );
};

export default ImageCenter;
